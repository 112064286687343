import { Link } from '@remix-run/react';
import clsx from 'clsx';
import { Logo } from '~/assets/Logo';
import { SplitGrid } from '~/components/split-grid';
import { Button } from '~/components/ui/button';
import { Routes } from '~/constants/routes';

export default function NotFoundPage() {
  return (
    <SplitGrid>
      <div className='mx-[15%] w-full'>
        <h2 className={clsx('scroll-m-20 text-3xl  tracking-tight')}>Page Not Found</h2>

        <p>This page doesn't exist. Let's bring you back home.</p>

        <Button
          asChild
          className='text-md mt-2 inline-flex items-center gap-2 rounded-sm bg-[#5A32FE] px-6 py-6 font-semibold uppercase'
        >
          <Link to={Routes.PublicMarketplace}>Go Home</Link>
        </Button>
      </div>

      <Logo className='w-auto h-40' />
    </SplitGrid>
  );
}
